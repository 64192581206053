<template>
	<div class="dash-auth-avatar-box">
		<!--<img class="dash-auth-avatar h-12 w-12 float-right" src="/assets/img/logos/dash_logo_md.png" />-->
		<div class="dash-auth-avatar-inner">
			<i class="dash-auth-avatar-icon fa-xl" :class="avatar"></i>
		</div>
	</div>
</template>

<script>
export default defineComponent({
	props: {
		avatar: {
			type: String,
			default: "fa-solid fa-unlock-keyhole"
		}
	}
});
</script>

<script setup>
import { defineComponent } from 'vue';
</script>
